import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'

import styled from '@emotion/styled'
import { typography, mq, colors, util } from 'src/styles'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import Container from 'src/components/Container'
import FullMedia from 'src/components/FullMedia'
import GeneralContentBlock from 'src/components/GeneralContentBlock'
import BlockText from 'src/components/BlockText'
import ImageGroup from 'src/components/ImageGroup'
import Image from 'src/components/Image'
import Section from 'src/components/Section'
import ContentfulRichText from 'src/components/ContentfulRichText'

const propTypes = {
  data: PropTypes.object.isRequired,
}

const ProjContainer = styled(Container)`
  padding-top: 90px;
  padding-bottom: 100px;
`

const InfoBlock = styled.div`
  width: 66%;
  margin-left: auto;
  margin-bottom: 95px;
  ${ mq.mediumAndBelow } {
    width: 100%;
  }
`

const Eyebrow = styled.h6`
  font-family: ${ typography.primaryFont };
  font-weight: 400;
  color: ${ colors.green };
`

const CategoryTitle = styled.p`
  text-transform: uppercase;
  margin: 1.25em 0 0;
  .author {
    text-transform: capitalize;
  }
`

const AuthorInfo = styled.p`
  margin: 0;
`

const ProjectAuthors = styled.div`
  margin-top: 10px;
`

const BackgroundBlock = styled(GeneralContentBlock)`
  margin-top: 100px;
`

const SectionsWrap = styled.div``

const OverviewBlock = styled(BlockText)`
  margin-top: 100px;
`

const ProposalImageWrap = styled.div`
  width: 50%;
  margin: 0 auto;
`

const Tools = styled.div`
  margin-top: 100px;
  p {
    ${ typography.bodyMedium }
  }
`
const Copyright = styled.div`
  margin-top: 100px;
  p {
    ${ typography.bodyMedium }
  }
`

const NsfFooter = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 100px;
  padding-left: 105px;
  padding-right: 105px;
  background: transparent;
  p {
    font-size: 14px;
    margin-top: 1.2em;
  }
  ${ mq.mediumAndBelow } {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    p {
      text-align: center;
    }
  }
`

const FooterLogoWrap = styled.div`
  width: 10%;
  max-width: 75px;
  margin-right: 20px;
  ${ mq.mediumAndBelow } {
    width: 20%;
    margin-right: 0;
  }
`

class ProjectTemplate extends React.Component {
  render () {
    const site =
      this.props.data.allContentfulSiteSettings &&
      this.props.data.allContentfulSiteSettings.edges[0].node
    const project = this.props.data.allContentfulProject.edges[0].node
    const seo = project.seo
    const { sections } = project

    console.log('project: ', project)
    return (
      <Fragment>
        <SEO
          title={project.projectTitle}
          description={seo.description && seo.description.description}
          siteSettings={site}
          keywords={seo.keywords}
          shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
        />

        <ProjContainer className={project.slug}>
          {project.projectCoverImage && (
            <FullMedia
              type="image"
              image={project.projectCoverImage}
              isFirstSection={true}
            />
          )}
          <InfoBlock>
            <Eyebrow>Vis-a-thon {project.projectYear}</Eyebrow>
            <h2>{project.projectTitle}</h2>

            {project.projectAuthors ? (
              <Fragment>
                <CategoryTitle>
                  Author{project.projectAuthors ? 's' : false}
                </CategoryTitle>

                <ProjectAuthors>
                  <ContentfulRichText richText={project.projectAuthors.json} />
                </ProjectAuthors>
              </Fragment>
            ) : (
              <Fragment>
                <CategoryTitle>
                  Author{project.projectAuthors ? 's' : false} <br />{' '}
                  <span className="author">{project.author}</span>
                </CategoryTitle>
                <AuthorInfo>{project.authorTitle}</AuthorInfo>
              </Fragment>
            )}
            <CategoryTitle>Critic</CategoryTitle>
            <AuthorInfo>{project.criticName}</AuthorInfo>
          </InfoBlock>

          <SectionsWrap>
            {sections &&
              sections.map((section, index) => {
                const lastSection = sections.length === index + 1
                return (
                  <ComponentRenderer
                    isFirstSection={index === 0}
                    isLastSection={lastSection}
                    key={section.id + index}
                    item={section}
                    index={index}
                  />
                )
              })}
          </SectionsWrap>

          <Tools>
            <h6>Tools Used in the Project </h6>
            <ContentfulRichText richText={project.tools.json} />
          </Tools>

          <Copyright>
            <h6>Copyright </h6>
            <ContentfulRichText richText={project.copyright.json} />
          </Copyright>
        </ProjContainer>
        {/*
				<Footer {...site} />
			*/}
        <NsfFooter>
          <FooterLogoWrap>
            <img src={'/nsf-logo.png'} />
          </FooterLogoWrap>
          <p>
            This material is based upon work supported in part by the National
            Science Foundation under EPSCoR Cooperative Agreement #OIA-1655221.{' '}
            <br /> Any opinions, findings, and conclusions or recommendations
            expressed in this material are those of the author(s) and do not
            necessarily reflect the views of the National Science Foundation.
          </p>
        </NsfFooter>
      </Fragment>
    )
  }
}

ProjectTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
    allContentfulSiteSettings(
      filter: { internalName: { regex: "/^((?!PLACEHOLDER).)*$/" } }
    ) {
      edges {
        node {
          ...SiteSettings
        }
      }
    }
    allContentfulProject(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          projectTitle
          slug
          projectYear
          projectCoverImage {
            ...ResponsiveImage
          }
          author
          authorTitle
          projectAuthors {
            json
          }
          criticName
          criticUrl
          sections {
            ...BlockText
            ...FullMedia
            ...ImageGroup
            ...SoundPlayer
            ...ProjectSlideshow
          }
          tools {
            json
          }
          copyright {
            json
          }
          seo {
            description {
              description
            }
            shareImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectTemplate
